<template>
  <div>
    <!-- 面包屑导航区域
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户信息管理</el-breadcrumb-item>
      <el-breadcrumb-item>系统用户列表</el-breadcrumb-item>
    </el-breadcrumb> -->
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 搜索框区域 -->
      <el-row :gutter="20">
        <el-col :span="15">
          <div>
            <el-input placeholder="姓名关键字" v-model="queryInfo.query" clearable @clear="query">
              <el-button slot="append" icon="el-icon-search" @click="query"></el-button>
            </el-input>
          </div>
        </el-col>
        <el-col :span="4">
          <div>
            <el-button type="primary" @click="addDialogVisible = true">添加用户</el-button>
          </div>
        </el-col>
      </el-row>
      <el-table :data="userList" :row-key="item"  border stripe table-layout='auto' class="tableAuto">
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column :width="flexWidth('LoginName',userList,'',20)" label="登录名" prop="LoginName"></el-table-column>
        <el-table-column :width="flexWidth('UserName',userList,'',20)" label="姓名" prop="UserName"></el-table-column>
        <el-table-column :width="flexWidth('RoleName',userList,'',20)" label="用户角色" prop="RoleName"></el-table-column>
        <el-table-column   label="用户所属" >
         <template slot-scope="scope" >
            <span v-if="scope.row.RoleName=='供应商'">{{ scope.row.SupplierName }}</span>
              <span  v-if="scope.row.RoleName=='业务人员'">{{ scope.row.SubscriptionPersonName }}</span>
          </template>
        </el-table-column>
        <el-table-column width="150px" label="状态（启用/停用）" prop="RoleName">
          <!-- 作用域插槽，绑定状态 -->
          <template slot-scope="scope">
            <el-switch v-if="scope.row.LoginName!=='admin'" v-model="scope.row.EnableFlag" @change="userStateChanged(scope.row)">
            </el-switch>
            <el-switch v-else disabled v-model="scope.row.EnableFlag" @change="userStateChanged(scope.row)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column class="button_column" label="操作" width="420px">
          <template slot-scope="scope">
            <el-button type="success" size="mini" @click="showEditDialog(scope.row.id)" v-if="scope.row.LoginName!=='admin'">编辑</el-button>
            <el-button type="success" size="mini" v-else disabled>编辑</el-button>
            <el-button type="danger" size="mini" @click="deleteConfirm(scope.row.id)" v-if="scope.row.LoginName!=='admin'">删除</el-button>
            <el-button type="danger" size="mini" v-else disabled>删除</el-button>
            <el-button type="warning" size="mini" @click="resetPwdConfirm(scope.row.id)" v-if="scope.row.LoginName!=='admin'">密码重置</el-button>
            <el-button type="warning" size="mini" v-else disabled>密码重置</el-button>
            <el-button type="primary" size="mini" @click="showAuthDialog(scope.row)" v-if="scope.row.RoleName =='供应商' || scope.row.RoleName =='业务人员'">所属分配</el-button>
            <el-button type="primary" size="mini" v-else disabled>所属分配</el-button>
           <el-button type="info" size="mini" @click="unbindWx(scope.row)">解除绑定</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination v-if="!isMobile" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pagenum" :page-sizes="[5, 10, 15, 20]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="totalRecordCount">
      </el-pagination>
      <el-pagination v-else @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pagenum" :page-sizes="[5, 10, 15, 20]" :page-size="queryInfo.pagesize" layout="total,sizes,prev, pager, next" :total="totalRecordCount">
      </el-pagination>

      <el-dialog title="添加用户" :visible.sync="addDialogVisible" width="100%" :close="addDialogClose" :close-on-click-modal="false">
        <el-form :model="addDialogForm" :rules="addDialogFormRules" ref="addDialogFormRef" label-width="100px" class="dialog_form">
          <el-form-item label="用户角色">
            <el-select v-model="addDialogForm.roleId" class="form-select" clearable filterable placeholder="请选择用户角色">
              <el-option v-for="item in roleList" :key="item.id" :label="item.RoleName" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          
          <el-form-item label="登录名称" prop="loginName">
            <el-input v-model="addDialogForm.loginName"></el-input>
          </el-form-item>
          <el-form-item label="用户姓名" prop="userName">
            <el-input v-model="addDialogForm.userName"></el-input>
          </el-form-item>
          <el-form-item label="手机号码" prop="phoneNumber">
            <el-input v-model="addDialogForm.phoneNumber" maxlength="11"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogClose">取 消</el-button>
          <el-button type="primary" @click="saveAdd">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog title="编辑用户" :visible.sync="editDialogVisible" width="100%" :close="editDialogClose" :close-on-click-modal="false">
        <el-form :model="editDialogForm" :rules="editDialogFormRules" ref="editDialogFormRef" label-width="100px" class="dialog_form">
          <el-form-item label="用户角色">
            <el-select v-model="editDialogForm.roleId" class="form-select" clearable filterable placeholder="请选择用户角色">
              <el-option v-for="item in roleList" :key="item.id" :label="item.RoleName" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="登录名称" prop="loginName">
            <el-input v-model="editDialogForm.loginName"></el-input>
          </el-form-item>
          <el-form-item label="用户姓名" prop="userName">
            <el-input v-model="editDialogForm.userName"></el-input>
          </el-form-item>
          <el-form-item label="手机号码" prop="phoneNumber">
            <el-input v-model="editDialogForm.phoneNumber" maxlength="11"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogClose">取 消</el-button>
          <el-button type="primary" @click="saveEdit">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog title="所属分配" :visible.sync="authDialogVisible" width="50%" :close="authDialogClose" :close-on-click-modal="false">
        <el-form :model="authDialogForm" ref="authDialogFormRef" label-width="100px" class="dialog_form">
          <div style="float:left;"><span style="color:#409eff;">用户姓名：</span>{{authDialogForm.row !== null ? authDialogForm.row.UserName : ''}}</div>
          <div style="margin-left:100px;padding-bottom:10px;"><span style="color:#409eff;">用户角色：</span>{{authDialogForm.row !== null ?authDialogForm.row.RoleName : ''}}</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <div>
                <el-input placeholder="请输入关键字" v-model="authDialogForm.query" clearable>
                  <el-button slot="append" icon="el-icon-search" @click="getAuthList"></el-button>
                </el-input>
              </div>
            </el-col>
          </el-row>
           <el-table v-if="authDialogForm.row !== null && authDialogForm.row.RoleName=='供应商'" :data="dataAuthList" border stripe>
            <el-table-column label="#" type="index"></el-table-column>
            <el-table-column label="名称" prop="SupplierName"></el-table-column>
            <el-table-column label="联系人" prop="SupplierContact"></el-table-column>
            <el-table-column label="联系方式" prop="SupplierPhoneNumber"></el-table-column>
            <el-table-column class="button_column" label="操作" width="150px">
              <template slot-scope="scope">
                <el-button type="success" size="mini" @click="addAuth(scope.row.NewsId)">选择</el-button>
              </template>
            </el-table-column>
          </el-table>

          <el-table v-if="authDialogForm.row !== null && authDialogForm.row.RoleName=='业务人员'" :data="dataAuthList" border stripe>
            <el-table-column label="#" type="index"></el-table-column>
            <el-table-column label="名称" prop="SubscriptionPersonName"></el-table-column>
            <el-table-column label="联系人" prop="SubscriptionPersonName"></el-table-column>
            <el-table-column label="联系方式" prop="PhoneNumber"></el-table-column>
            <el-table-column class="button_column" label="操作" width="150px">
              <template slot-scope="scope">
                <el-button type="success" size="mini" @click="addAuth(scope.row.NewsId)">选择</el-button>
              </template>
            </el-table-column>
          </el-table>

        </el-form>
         <!-- 分页区域 -->
        <el-pagination v-if="!isMobile" @size-change="handleSizeChanges" @current-change="handleCurrentChanges" :current-page="authDialogForm.pagenum" :page-sizes="[5, 10, 15, 20]" :page-size="authDialogForm.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="totalStaff">
        </el-pagination>
        <el-pagination v-else @size-change="handleSizeChanges" @current-change="handleCurrentChanges" :current-page="authDialogForm.pagenum" :page-sizes="[5, 10, 15, 20]" :page-size="authDialogForm.pagesize" layout="total,sizes,prev, pager, next" :total="totalStaff">
        </el-pagination>
        </el-dialog>

    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userList: [],
      totalRecordCount: 0,
      queryInfo: {
        query: "",
        pagenum: 1,
        pagesize: 10,
        token: window.sessionStorage.getItem("token"),
        loginname: window.sessionStorage.getItem("loginname"),
      },
      addDialogVisible: false,
      editDialogVisible: false,
      authDialogVisible: false,
      addDialogForm: {
        phoneNumber: "",
        loginName: "",
        userName: "",
        roleId: "",
      },
      editDialogForm: {
        phoneNumber: "",
        loginName: "",
        userName: "",
        roleId: "",
        id: 0,
      },
      authDialogForm: {
         query: "",
        pagenum: 1,
        pagesize: 10,
        row:null,
      },
      totalStaff:0,
      dataAuthList:[],
      row:{
        UserName:'',
        RoleName:''
      },
      companyList: [],
      roleList: [],
      isMoblile:false,
      totalAuthCount:0,

      addDialogFormRules: {
        loginName: [
          { required: true, message: "请输入登录名称", trigger: "blur" },
          {
            min: 1,
            max: 30,
            message: "长度在 1 到 30 个字符",
            trigger: "blur",
          },
        ],
        userName: [
          { required: true, message: "请输入用户姓名", trigger: "blur" },
          {
            min: 1,
            max: 30,
            message: "长度在 1 到 30 个字符",
            trigger: "blur",
          },
        ],
        phoneNumber: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            min: 11,
            max: 11,
            message: "长度在11 个字符",
            trigger: "blur",
          },
        ],
      },
      editDialogFormRules: {
        loginName: [
          { required: true, message: "请输入登录名称", trigger: "blur" },
          {
            min: 1,
            max: 30,
            message: "长度在 1 到 30 个字符",
            trigger: "blur",
          },
        ],
        userName: [
          { required: true, message: "请输入用户姓名", trigger: "blur" },
          {
            min: 1,
            max: 30,
            message: "长度在 1 到 30 个字符",
            trigger: "blur",
          },
        ],
        phoneNumber: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            min: 11,
            max: 11,
            message: "长度在11 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.getUserList();
    this.getUserRoleList();
    var isMobileTemp=window.sessionStorage.getItem("isMobile");
    this.userNewsId=window.sessionStorage.getItem("userNewsId");
    if(isMobileTemp=='true'){
      this.isMobile=true;
    }else{
      this.isMobile=false;
    }
  },
  methods: {
    /* 因为是返回的promise对象，所以用async及await */
    async getUserList() {
      //this.$http.post("User/GetUserList", this.queryInfo)
      var paramVar = this.$qs.stringify({
        query: this.queryInfo.query,
        pagenum: this.queryInfo.pagenum,
        pagesize: this.queryInfo.pagesize,
        
      });
      //await this.$http.get("User/GetUserList", { params: this.queryInfo }).then(
      await this.$http.post("User/GetUserList", paramVar).then(
        (res) => {
          if (res.data.Code !== "200")
            return this.$message.error(res.data.Message);
          this.userList = res.data.Obj;
          this.totalRecordCount = res.data.TotalCount;

          //获取列表数据之后，计算每列最大宽度
          //const columns = this.$common.calcColumnsWidth(this.tableHead, res.data.Obj);
          //this.tableHead = columns;
        },
        (res) => {
          this.$message.error("远程获取数据失败");
        }
      );
    },
    change(e) {
      this.$forceUpdate();
    },
    query() {
      this.getUserList();
    },
    /* 监听 pagesize 改变事件 */
    handleSizeChange(newsize) {
      this.queryInfo.pagesize = newsize;
      this.getUserList();
    },
    /* 监听 当前页 改变事件 */
    handleCurrentChange(newpage) {
      this.queryInfo.pagenum = newpage;
      this.getUserList();
    },
    handleSizeChanges(newsize) {
      this.authDialogForm.pagesize = newsize;
      this.getAuthList();
    },
    /* 监听 当前页 改变事件 */
    handleCurrentChanges(newpage) {
      this.authDialogForm.pagenum = newpage;
      this.getAuthList();
    },
    /* 监听switch用户状态改变事件 */
    async showAuthDialog(userinfo) {
      await this.$http
        .post("User/UpdateUserState/", {
          id: userinfo.id,
          enableflag: userinfo.EnableFlag,
        })
        //.put('User/UpdateUserState/${userinfo.id}/${userinfo.EnableFlag}')
        .then(
          (res) => {
            if (res.data.Code !== "200") {
              userinfo.EnableFlag = !userinfo.EnableFlag;
              return this.$message.error(res.data.Message);
            }
            this.$message.success("用户状态更新成功");
          },
          (res) => {
            userinfo.EnableFlag = !userinfo.EnableFlag;
            this.$message.error("远程操作失败或网络问题");
          }
        );
    },
    addDialogClose() {
      this.addDialogForm.roleId = "";
      this.addDialogVisible = false;
      this.$refs.addDialogFormRef.resetFields();
    },
    async getUserRoleList() {
      await this.$http.get("UserRole/GetUserRoleListByPage/").then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.roleList = res.data.Obj;
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    saveAdd() {
      if (this.addDialogForm.roleId === "")
        return this.$message.error("请选择用户角色");
      this.$refs.addDialogFormRef.validate((valid) => {
        if (!valid) return this.$message.error("关键信息不能为空");
        this.submitAdd();
      });
    },
    async submitAdd() {
      console.log(this.addDialogForm);
      await this.$http.post("User/AddUser/", this.addDialogForm).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$refs.addDialogFormRef.resetFields();
          this.addDialogVisible = false;
          this.$message.success(res.data.Message);
          this.query();
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    editDialogClose() {
      this.editDialogForm.roleId = "";
      this.editDialogVisible = false;
      this.$refs.editDialogFormRef.resetFields();
    },
    showEditDialog(id) {
      var user = null;
      for (var i = 0; i < this.userList.length; i++) {
        if (this.userList[i].id === id) user = this.userList[i];
      }
      this.editDialogForm.roleId = user.RoleId;
      this.editDialogForm.loginName = user.LoginName;
      this.editDialogForm.userName = user.UserName;
      this.editDialogForm.phoneNumber = user.PhoneNumber;
      this.editDialogForm.id = id;
      this.editDialogVisible = true;
    },
    saveEdit() {
      if (this.editDialogForm.roleId === "")
        return this.$message.error("请选择用户角色");
      this.$refs.editDialogFormRef.validate((valid) => {
        if (!valid) return this.$message.error("关键信息不能为空");
        this.submitEdit();
      });
    },
    async submitEdit() {
      await this.$http.post("User/EditUser/", this.editDialogForm).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$refs.editDialogFormRef.resetFields();
          this.editDialogVisible = false;
          this.$message.success(res.data.Message);
          this.query();
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    deleteConfirm(id) {
      this.$confirm("您确实要删除此记录吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //执行删除方法
          this.deleteUser(id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    async deleteUser(id) {
      await this.$http.post("User/DeleteUser/", { id: id }).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$message.success(res.data.Message);
          this.query();
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
     async userStateChanged(userinfo) {
      await this.$http
        .post("User/UpdateUserState/", {
          id: userinfo.id,  
          EnableFlag: userinfo.EnableFlag,
        })
        //.put('User/UpdateUserState/${userinfo.id}/${userinfo.EnableFlag}')
        .then(
          (res) => {
            if (res.data.Code !== "200") {
              userinfo.EnableFlag = !userinfo.EnableFlag;
              return this.$message.error(res.data.Message);
            }
            this.$message.success("用户状态更新成功");
          },
          (res) => {
            userinfo.EnableFlag = !userinfo.EnableFlag;
            this.$message.error("远程操作失败或网络问题");
          }
        );
    },
    resetPwdConfirm(id) {
      this.$confirm("您确实要重置此记录的密码吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //执行删除方法
          this.resetPwd(id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    async resetPwd(id) {
      await this.$http.post("User/ResetPwd/", { id: id }).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$message.success(res.data.Message);
          this.query();
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    showAuthDialog(row) {
      this.authDialogForm.row = row;
      console.log(row)
      this.authDialogVisible = true;
      this.getAuthList()
    },
    authDialogClose() {
      this.authDialogVisible = false;
    },
    saveAuth() {
      if (this.authDialogForm.MyCustomerNewsId === "")
        return this.$message.error("请选择所属往来单位");
      this.submitAuth();
    },
    async submitAuth() {
      await this.$http.post("User/AuthUser/", this.authDialogForm).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.authDialogVisible = false;
          this.$message.success(res.data.Message);
          this.getUserList();
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    async getCustomerList() {
      console.log(this.authDialogForm.MyCustomerName);
      var paramVar = this.$qs.stringify({
        MyCustomerName: this.authDialogForm.MyCustomerName
      });
      await this.$http.post("Company/GetCompanyListByPage").then(
        (res) => {
          if (res.data.Code !== "200")
            return this.$message.error(res.data.Message);
          this.companyList = res.data.Obj;
        },
        (res) => {
          this.$message.error("远程获取数据失败");
        }
      );
    },
    setMyCustomerNewsId(newsid, name) {

      this.authDialogForm.CompanyCode = newsid;
      this.authDialogForm.setMyCustomerName = name;
    },
    //所属分配-供应商
    async getAuthList() {
      let url = '';
      if(this.authDialogForm.row.RoleName == '供应商'){
        url = "Supplier/GetList";
      }
      if(this.authDialogForm.row.RoleName == '业务人员'){
        url = "SubscriptionPerson/GetList";
      }
      console.log(this.authDialogForm.row)
      await this.$http
        .get(url ,{ params: this.authDialogForm})
        .then(
          (res) => {
            if (res.data.Code !== "200")
              return this.$message.error(res.data.Message);
              this.dataAuthList = res.data.Obj;
              this.totalAuthCount = res.data.TotalCount;
              console.log(this.dataAuthList)
              this.row.UserName = this.authDialogForm.row.userName
              this.row.RoleName = this.authDialogForm.row.RoleName
              this.totalStaff = res.data.TotalCount;
          },
          (res) => {
              this.$message.error("远程获取数据失败");
          }
        );
    },
    addAuth(NewsId) {
      this.$confirm("您确实要选择此记录吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //执行删除方法
          this.addAuthExe(NewsId);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    async addAuthExe(NewsId){
      let data ={
        id:this.authDialogForm.row.id,
        SupplierNewsId:NewsId
      }
      
      await this.$http.post("User/AuthUser", data).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$message.success(res.data.Message);
          this.authDialogVisible = false;
          this.getUserList();

        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    //解除绑定
    unbindWx(row){
      this.$confirm("您确实要解绑此用户吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //执行删除方法
          this.unbindWxExe(row.id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    async unbindWxExe(id){
      // return console.log(row)
      let that = this;
      let data = {
        id:id
      }
      // console.log(data)
      await that.$http
          .post('User/ResetWxUserByWeb',data)
					.then(
          (res) => {
            console.log(res)
            if (res.data.Code !== "200") return that.$message.error(res.data.Message);
          },
          (res) => {
            that.$message.error("远程获取数据失败");
          }
        );
    },
    flexWidth(prop, tableData, title, num = 0) {
      if (tableData.length === 0) {//表格没数据不做处理
        return;
      }
      let flexWidth = 0;//初始化表格列宽
      
      flexWidth = this.$common.flexWidth(prop, tableData, title, num );
      return flexWidth + 'px';
    },
  },
};
</script>

<style lang="less" scoped>
.el-table .cell {
  white-space: pre-line;
}
</style>